body {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Primary Navigation */
nav{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff; /* Add background color to ensure it's visible */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow to separate from the content */
  z-index: 1000; /* Ensure it's above other content */
}

.tab-list {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  background: rgb(79,42,118);
  background: linear-gradient(65deg, rgba(79,42,118,1) 26%, rgba(111,99,135,1) 85%);
  font-size: 14px;
}

.tab-list li {
  margin: 0 5px;
  text-align: center;
  display: flex;
}

.tab-list li img{
  height:30px;
  width: 30px;
}

.tab-list li a.active{
  color:#BEBED2;
  filter: brightness(0) saturate(100%) invert(89%) sepia(7%) saturate(564%) hue-rotate(202deg) brightness(86%) contrast(90%);
}

.tab-list a {
  text-decoration: none;
  color: white;
  padding: 10px 15px;
}

/* Page Layout */
#header{
  background: #370F6E;
  background-image: url("./images/dpek-text.png");
  background-repeat: repeat-x;
}

.main-content {
  flex: 1;
  padding-bottom: 120px;
}

.pageImage{
  width: 100%;
  border-bottom: 6px #370f6e solid;
}

.page-content {
  flex: 1;
  padding: 0 20px;
}

.page-content h2{
  margin: 20px 0 0 0;
  font-size: 24px;
}

.page-content h3{
  margin: 30px 0 10px 0;
  font-size: 16px;
  color: #370F6E;
}

.page-content p{
  margin: 0 0 5px 0;
  line-height: 20px;
  font-size: 14px;
}

.page-content p a{
  color: #8700ff;
  text-decoration: none;
}

/* Agenda layout */
ul.agenda{
  padding-left: 0;
  clear: both;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  width: 100%;
}

ul.agenda li.day{
  padding: 0;
  margin: 0 0 50px 0;
  max-width:600px;
  clear: both;
}

ul.agenda li.day ul.time{
  padding: 0;
  margin: 0;
  clear: both;
  list-style: none;
}

ul.agenda li.day ul.time li{
  float: left;
  margin: 10px 0 0 20px;
  padding: 0;
}

ul.agenda li.day ul.time li:first-child{
  margin-left: 0;
  font-weight: bold;
}

ul.agenda li.day ul.time li:last-child{
  float: right;
}
